<!--
 * @Author: Neko
 * @Date: 2021-01-07 13:33:51
 * @LastEditTime: 2021-03-23 09:38:20
 * @LastEditors: Neko
-->
<template>
  <div class="login__container">
    <div class="login__wrap">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginView'
}
</script>

<style lang="scss" scoped>
.login__container {
  position: relative;
  min-height: 620px;
  // background: url(../../assets/images/login/bg.png) no-repeat;
  background-position: top center;
  background-size: cover;
}

.login__wrap {
  position: absolute;
  width: 860px;
  height: 672px;
  // padding: 30px 34px 0;
  // margin-top: 90px;
  left: 50%;
  transform: translateX(-50%);
  // background: #FFFFFF;
  // box-shadow: 0px 17px 102px 15px rgba(232, 232, 234, 0.95);
  border-radius: 8px;
  // overflow: hidden;
}
</style>
